

let Address: any;
let ActiveAddress: string;
export function FilterAddCode(address: string, List: any): any {
    Address = List;
    ActiveAddress = address
    return (new Promise((reslove, rejeact) => {

        let ShengCode: string | number;
        let ShiCode: string | number;
        let XianCode: string | number;

        let ShengCodeBool = false
        let ShiCodeBool = false
        let XianCodeBool = false
        let Length: number;


        // 检测 是否判断完毕
        function IsCode() {
            let list: Array<any> = [ShengCodeBool, ShiCodeBool, XianCodeBool]
            let num = 0;
            for (let i = 0; i < Length; i++) {
                if (list[i]) {
                    num++;
                }
            }
            if (num === Length) {
                switch (Length) {
                    case 1:
                        reslove(ShengCode)
                        break;
                    case 2:
                        reslove(ShiCode)
                        break;
                    case 3:
                        reslove(XianCode)
                        break;
                }
            }
        }

        function SearchCode(key: string | number) { // 河南
            if (!Boolean(key)) return;
            let Sheng = Address.provinceList
            let ShengList = Object.keys(Address.provinceList)
            let reg = new RegExp(String(key))
            let a = 0;
            let timeFor = setInterval(() => {
                if (reg.test(Sheng[ShengList[a]])) {
                    clearInterval(timeFor)
                    ShengCode = ShengList[a]
                    ShengCodeBool = true
                    IsCode()
                }
                a++;
            })
        }

        function SearchCity(key: string | number) { // 郑州
            if (!Boolean(key)) return;
            let Shi = Address.cityList
            let ShiList = Object.keys(Address.cityList)
            let reg = new RegExp(String(key))
            let a = 0;
            let timeFor = setInterval(() => {
                if (reg.test(Shi[ShiList[a]])) {
                    clearInterval(timeFor)
                    ShiCode = ShiList[a]
                    ShiCodeBool = true
                    IsCode()
                }
                a++;
            })
        }

        function SearchXian(key: string | number) { // 浚县 或者 区
            if (!Boolean(key)) return;
            let Xian = Address.countyList
            let XianList = Object.keys(Address.countyList)
            let reg = new RegExp(String(key))
            let a = 0;
            let timeFor = setInterval(() => {
                if (reg.test(Xian[XianList[a]])) {
                    clearInterval(timeFor)
                    XianCode = XianList[a]
                    XianCodeBool = true
                    IsCode()
                }
                a++;
            })
        }

        function InitData(): void { // 初始化 数据
            let addressList = ActiveAddress.split(" ")
            Length = addressList.length
            // 最终判断 选中的code
            switch (Length) {
                case 2:
                    SearchCode(addressList[0])
                    SearchCity(addressList[1])
                    break;
                case 3:
                    SearchCode(addressList[0])
                    SearchCity(addressList[1])
                    SearchXian(addressList[2])
                    break;
            }
        }


        // 启动 脚本
        InitData()


    })

    )
}

